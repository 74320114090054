@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: inter;
  font-size: 13px;
  color: #fefefe;
  background: #151520;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 2s ease 0.3s forwards;
  animation-delay: 0s;
}

svg {
  fill: #fefefe;
}

a {
  color: white;
}

a {
  pointer-events: all;
  color: white;
  text-decoration: none;
}
